<template>
  <iframe
    id="balance-history-komads"
    frameborder="1"
    :height="`${height}px`"
    width="100%"

    scrolling="no"
    :src="`${url}/marketing/balance-history`"
  />

</template>

<script>
export default {
  data() {
    return {
      url: `${process.env.VUE_APP_BASE_AUTH_MODULE}`,
      height: 0,
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.url) {
        const { type, height, route } = event.data

        if (type === 'SET_DIMENSION') {
          this.height = Math.floor(height)
        }
        if (route) {
          this.$router.push(route)
        }
      }
    })
  },

}
</script>
